import * as React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Container, Card } from 'react-bootstrap';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

const About = ({ data }) => {
  return (
    <Layout>
      <Container className="my-3">
        <Seo title="運営情報" />
        <h1 className="h2">運営者情報</h1>
        <div className="row">
          <div className="col-lg-6 col-12 my-3 px-3">
            <Card className="py-4 px-4 h-100">
              <div className="text-center">
                <StaticImage
                  className="bio-avatar rounded-circle"
                  // layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/author1-profile-pic.jpeg"
                  width={60}
                  height={60}
                  quality={95}
                  alt="Profile picture"
                />
                <div className="h5">{ data.site.siteMetadata.developer[0].name }</div>
              </div>
              <div className="py-2 px-2">
                { data.site.siteMetadata.developer[0].biography }
              </div>
              <div className="py-2 px-2">
                <div>
                  <FontAwesomeIcon className="fa-fw text-secondary" icon={faTwitter}></FontAwesomeIcon>
                  Twitter: <a href="https://twitter.com/dustium162">@{ data.site.siteMetadata.developer[0].social.twitter }</a>
                </div>
                <div>
                  <FontAwesomeIcon className="fa-fw text-secondary" icon={faGithub}></FontAwesomeIcon>
                  GitHub: <a href="https://github.com/dustium162">{ data.site.siteMetadata.developer[0].social.github }</a>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-6 col-12 my-3 px-3">
            <Card className="py-4 px-4 h-100">
              <div className="text-center">
                <StaticImage
                  className="bio-avatar rounded-circle"
                  // layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/author2-profile-pic.png"
                  width={60}
                  height={60}
                  quality={95}
                  alt="Profile picture"
                />
                <div className="h5">{ data.site.siteMetadata.developer[1].name }</div>
              </div>
              <div className="py-2 px-2">
                { data.site.siteMetadata.developer[1].biography }
              </div>
              <div className="py-2 px-2">
                <div>
                  <FontAwesomeIcon className="fa-fw text-secondary" icon={faTwitter}></FontAwesomeIcon>
                  Twitter: <a href="https://twitter.com/amount86">@{ data.site.siteMetadata.developer[1].name }</a>
                </div>
                <div>
                  <FontAwesomeIcon className="fa-fw text-secondary" icon={faGithub}></FontAwesomeIcon>
                  GitHub: <a href="https://github.com/amount86">amount86</a>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="mt-5 text-center">
          <Link to="/" className="text-secondary text-decoration-none">記事一覧に戻る</Link>
        </div>
      </Container>
    </Layout>
  )
}

export default About;

export const query = graphql`
query {
  site {
    siteMetadata {
      developer {
        name
        biography
        image
        social {
          twitter
          github
        }
      }
    }
  }
}
`